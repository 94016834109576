import logo from './logo.png'

export const theme = {
    color: {
      primary: "#0699e6",
      primaryHover: "#0589cf",
    },
    logoImage: logo,
    backend: "https://tennis-epaper-kiosk.de/",
    directory: "/",
    header: {
      customer: "",
      title: "Tennis ePaper-Kiosk",
      lineHeader: true
    },
    info: [
      {
      text: 'Impressum',
      link: 'https://tennisfreunde24.de/impressum'
    },
      {
      text: 'AGB',
      link: 'https://tennisfreunde24.de/agb'
    },
      {
      text: 'Datenschutz',
      link: 'https://tennisfreunde24.de/datenschutzerkl%c3%a4rung'
    },
  ],
  login: {
    type: "internal",
    subscriptionTitle: "Abo",
    subscriptionText: "Du hast noch kein Tennisfreunde24 Magazin ABO? Schließe jetzt dein ABO ab und erhalte vollen digitalen Zugriff auf die aktuelle Ausgabe, alle künftigen Ausgaben und unser Archiv. ABOs sind als Epaper und Print erhältlich.",
    links: [{
      id: 1,
      text: 'Abo abschließen',
      url: "https://abo-tennis-epaper-kiosk.de/"
    }
    ]
  }
  };